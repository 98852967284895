import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from 'styled-components';
import StyledButton from '../components/StyledButton';

import casablancaBG from '../images/banner/casablanca-bg-1200x600.jpg';

const FlexRow = styled.div`
    display: flex;
    padding: 150px 0;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
    background-color: #222222;
    background-image: url(${casablancaBG});
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;

    border-top-width: 2px;
    border-top-style: dashed;
    border-top-color: white;

    border-bottom-width: 2px;
    border-bottom-style: dashed;
    border-bottom-color: white;    
`;

const SubSectionFlex = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: row;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }
`;

const WhiteH1 = styled.h1`
    color: white;
    padding-left: 5%;
    padding-right: 5%;
`;

const WhiteH6 = styled.h6`
    color: white;
    padding-left: 5%;
    padding-right: 5%;    
`;

const PaddedButton = styled(StyledButton)`
    margin: 10px 0 0 20px;

    @media only screen and (max-width: 600px) {
        margin-left: 0px;
    }    
`;

const AbstractSection = () => {
    const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          company {
              phone
          }
        }
      }
    }
  `)

    return (
        <FlexRow>
    
            <div>
                <WhiteH1>Our Goal is to Improve the Value and Appearance of your Property</WhiteH1>
    
                <WhiteH6>We are professionals, but don't take our word for it, checkout our work or contact us now and we'll bring you physical samples to see</WhiteH6>
            </div>
    
            <SubSectionFlex>
                <a href="#ourCurbs" alt="View photographs of curbs installed by Rock Solid Borders">
                    <StyledButton
                        style={{
                            margin: '10px 0'
                        }}
                    >
                        Our Work
                    </StyledButton>
                </a>

                <a href="#freeEstimate" alt={`Call (${data.site.siteMetadata.company.phone.slice(1, 4)}) ${data.site.siteMetadata.company.phone.slice(4, 7)}-${data.site.siteMetadata.company.phone.slice(7, 11)} to get your free estimate from Rock Solid Borders, Southeast Iowa's Curbing Pros`}>
                    <PaddedButton>Get In Touch Now</PaddedButton>
                </a>                
            </SubSectionFlex>
    
        </FlexRow>
    )
}

export default AbstractSection