import React from "react"

import Card from 'react-bootstrap/Card';
import CardColumns from 'react-bootstrap/CardColumns';

import pic5 from  '../images/437-ellis-creek-lane/20200804_154651.jpg';
import pic6 from  '../images/437-ellis-creek-lane/20200804_154734.jpg';
import pic7 from  '../images/437-ellis-creek-lane/20200811_111457.jpg';
import pic8 from '../images/437-ellis-creek-lane/20200811_111632.jpg';
import pic9 from '../images/437-ellis-creek-lane/20200811_111513.jpg';
// lilbubba images
import lil1 from '../images/lilbubba/20180910_121121.jpg';
import lil2 from '../images/lilbubba/20180910_121503.jpg';
import lil3 from '../images/lilbubba/20180910_140045.jpg';
import lil4 from '../images/lilbubba/Casablanca1.jpg';

const ImageGrid = () => (
    <CardColumns>
        <Card>
            <Card.Img src={lil4} />
        </Card>

        <Card>
            <Card.Img src={pic8} />
        </Card>

        <Card>
            <Card.Img src={lil2} />
        </Card>        

        <Card>
            <Card.Img src={pic7} />
        </Card>

        <Card>
            <Card.Img src={lil3} />
        </Card>

        <Card>
            <Card.Img src={pic5} />
        </Card>

        <Card>
            <Card.Img src={lil1} />
        </Card>        

        <Card>
            <Card.Img src={pic6} />
        </Card>

        <Card>
            <Card.Img src={pic9} />
        </Card>                

        {/* <Card bg="primary" text="white" className="text-center p-3">
            <Card.Img src={pic2} />
            <blockquote className="blockquote mb-0 card-body">
            <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere
                erat a ante.
            </p>
            <footer className="blockquote-footer">
                <small className="text-muted">
                Someone famous in <cite title="Source Title">Source Title</cite>
                </small>
            </footer>
            </blockquote>
        </Card> */}
    </CardColumns>
)

export default ImageGrid