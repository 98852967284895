import React, { Component } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import styled from 'styled-components';

import StyledButton from './StyledButton';

import pic1 from  '../images/437-ellis-creek-lane/20200804_154538.jpg';
import pic3 from  '../images/437-ellis-creek-lane/20200804_154614.jpg';
import pic4 from  '../images/437-ellis-creek-lane/20200804_154637.jpg';
import pic5 from  '../images/437-ellis-creek-lane/20200804_154651.jpg';
import pic6 from  '../images/437-ellis-creek-lane/20200804_154734.jpg';
import pic7 from  '../images/437-ellis-creek-lane/20200811_111457.jpg';
import pic8 from '../images/437-ellis-creek-lane/20200811_111632.jpg';
// lilbubba images
import lil1 from '../images/lilbubba/Casablanca2.jpg';
import lil2 from '../images/lilbubba/BerberBlock2.jpg';
import lil3 from '../images/lilbubba/Casablanca1.jpg';
import lil4 from '../images/lilbubba/Berber Block 2.png';
import lil5 from '../images/lilbubba/Berber Block 01.png';
import lil6 from '../images/lilbubba/20180910_140045.jpg';

const LightboxContainer = styled.div`
  padding: 2%;
`;

const images = [
  pic8,
  pic7,
  lil1,
  lil2,
  lil3,
  lil4,
  lil5,
  lil6,
  pic3,
  pic4,
  pic5,
  pic6,
  pic1
];

export default class CurbsLightbox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      photoIndex: 0,
      isOpen: false,
    };
  }

  render() {
    const { photoIndex, isOpen } = this.state;

    return (
      <LightboxContainer>

        <StyledButton
          onClick={() => this.setState({ isOpen: true })}
        >View More Curbs</StyledButton>        

        {isOpen && (
          <Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={images[(photoIndex + images.length - 1) % images.length]}
            onCloseRequest={() => this.setState({ isOpen: false })}
            onMovePrevRequest={() =>
              this.setState({
                photoIndex: (photoIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                photoIndex: (photoIndex + 1) % images.length,
              })
            }
          />
        )}
      </LightboxContainer>
    );
  }
}