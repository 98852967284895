import styled from 'styled-components';
import config from '../globalVariables';

const StyledButton = styled.button`
  background-color: white;
  padding: 15px 30px;
  border-radius: 50px;
  border: 6px solid ${config.baseColor};
  font-weight: bold;

  :hover {
    border: 6px solid #222222;
    font-style: italic;
  }
`;

export default StyledButton;