import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import styled from 'styled-components';

// import ContactForm from './ContactForm'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import curbIsland from '../images/banner/curb-island-1200x600.jpg';

import config from '../globalVariables';

const StyledRow = styled(Row)`
  background-image: url(${curbIsland});
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  text-align: center;
  background-color: #eee;
  // padding: 10% 0;
  padding: 150px 0;

  border-top-width: 2px;
  border-top-style: dashed;
  border-top-color: white;

  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: white;    
`;

const OrangeLink = styled.a`
    color: ${config.baseColor};

    :hover {
      color: ${config.baseColor};
      font-style: italic;
    }
`;

const WhiteH1 = styled.h1`
  color: white;'
`;

// const WhiteH6 = styled.h6`
//   color: white;'
// `;

const FreeEstimateSection = () => {
    const data = useStaticQuery(graphql`
    query ContactNumberQuery {
      site {
        siteMetadata {
          company {
              phone
          }
        }
      }
    }
  `)

    return (
        <StyledRow id="freeEstimate">
            <Col>
                {/* <ContactForm /> */}
                <WhiteH1>Call for your FREE estimate</WhiteH1>

                {/* <WhiteH6>We will bring actual curb samples for you to see</WhiteH6> */}

                <h1>
                    <OrangeLink
                        href={`tel:${data.site.siteMetadata.company.phone}`}
                        alt="Contact number for Rock Solid Borders"
                    >
                        {`(${data.site.siteMetadata.company.phone.slice(1, 4)}) ${data.site.siteMetadata.company.phone.slice(4, 7)}-${data.site.siteMetadata.company.phone.slice(7, 11)}`}
                    </OrangeLink>
                </h1>
            </Col>
        </StyledRow>
    )
}

export default FreeEstimateSection