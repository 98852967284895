import React from "react"

import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

import MutedSubtitle from './MutedSubtitle';
import SectionTitle from './SectionTitle';

import curb1 from '../images/lilbubba/Casablanca2.jpg';
import RainDrain from '../images/437-ellis-creek-lane/20200804_154714.jpg';
import treeRing from  '../images/437-ellis-creek-lane/20200811_111513.jpg';

const StyledRow = styled(Row)`
  background-color: #eeeeee;
  min-height: 300px;
  padding: 5% 0;
`;

const StyledCol1 = styled(Col)`
    text-align: center;
`;

const AboutSection = () => (
    <StyledRow id="about">
        <StyledCol1 lg={12}>
            <SectionTitle>Southeast Iowa's Best Choice for Landscape Border Edging</SectionTitle>

            <MutedSubtitle>We Take Pride in Our Work and Aim to Deliver One-of-a-kind Concrete Curbing</MutedSubtitle>
        </StyledCol1>

        <Col md="4" sm="12">
            <Card>
                <Card.Img variant="top" src={curb1} />

                <Card.Body>
                    <Card.Title>Natural "Stone" Curbs</Card.Title>
                    <Card.Text>
                        Concrete landscape curbs are the best edging that exists.  Not only do they improve the appearance of your home and landscape, they keep your garden bed in-place.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>

        <Col md="4" sm="12">
            <Card>
                <Card.Img variant="top" src={RainDrain} />

                <Card.Body>
                    <Card.Title>Gutter Rain Drains</Card.Title>
                    <Card.Text>
                        Rain drains protect your homes foundation from water damage.  They also extend the life of your concrete curbing by moving water outside of your curbing.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>

        <Col md="4" sm="12">
            <Card>
                <Card.Img variant="top" src={treeRing} />

                <Card.Body>
                    <Card.Title>Tree Rings</Card.Title>
                    <Card.Text>
                        Tree rings made of concrete won't rust nor move, unlike other border edging. Tree rings protect the base of your trees and your tree lights from damage by your mower or weed wacker.
                    </Card.Text>
                </Card.Body>
            </Card>
        </Col>                

    </StyledRow>
)

export default AboutSection