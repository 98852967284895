import React from "react"

import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ImageGrid from  './ImageGrid';
import MutedSubtitle from './MutedSubtitle';
import SectionTitle from './SectionTitle';

import CurbsLightbox from './CurbsLightbox';

const StyledRow = styled(Row)`
  min-height: 300px;
`;

const StyledCol = styled(Col)`
  text-align: center;
`;

const OurCurbsSection = () => (
    <StyledRow id="ourCurbs">
        <StyledCol>
            <SectionTitle>Our Landscape Curbs Make Great Border Edging for your Flower Beds</SectionTitle>

            <MutedSubtitle>Our Curbs are not just decorative, they are permanent and durable so your gardens stay in place and protected</MutedSubtitle>
        </StyledCol>

        <Col lg="12">
            <ImageGrid />
        </Col>

        <StyledCol>
            <CurbsLightbox />
        </StyledCol>

    </StyledRow>
)

export default OurCurbsSection