import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import AboutSection from "../components/AboutSection"
import OurCurbsSection from "../components/OurCurbsSection"
import FreeEstimateSection from "../components/FreeEstimateSection"
import AbstractSection from "../components/AbstractSection"
// import CurbStylesSection from "../components/CurbStylesSection"
// import CurbSectionDivider from "../components/CurbSectionDivider"
// import TestimonialSlider from "../components/TestimonialSlider"

const IndexPage = () => (
  <Layout>

    <SEO title="Makes Concrete Landscape Border Edging" />

    <Hero />

    <AboutSection />

    {/* <CurbStylesSection /> */}
    
    {/* <TestimonialSlider /> */}

    <AbstractSection />

    {/* <CurbSectionDivider /> */}

    <OurCurbsSection />

    <FreeEstimateSection />    
    
  </Layout>
)

export default IndexPage
