import React from "react"

import styled from 'styled-components';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import StyledButton from './StyledButton';

import curbCloseUp from '../images/banner/curb-close-up-bg-1200x600.jpg';

const StyledJumbotron = styled(Row)`
  padding: 13%;
  background-image: url(${curbCloseUp});
  background-color: #cccccc;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  color: white;
  border-top-width: 2px;
  border-top-style: dashed;
  border-top-color: #eeeeee;

  border-bottom-width: 2px;
  border-bottom-style: dashed;
  border-bottom-color: #eeeeee;
`;

const StyledCol = styled(Col)`
  text-align: center;
`;

const Hero = () => (
  <StyledJumbotron>
    <StyledCol>
      <h1>Southeast Iowa's Landscape Curbing Pros</h1>

      <p>
        We specialize in Natural "Stone" Concrete Curbing for adding value and curb appeal to your home!
      </p>

      <p>
          {/* <a
            href="#curbStyles"
            alt="View the styles of concrete border edging which Rock Solid Borders creates"
          >
            <StyledButton>
                View Our Curb Styles
            </StyledButton>
          </a> */}
          <a
            href="#ourCurbs"
            alt="View the styles of concrete border edging which Rock Solid Borders creates"
          >
            <StyledButton>
                View Our Curbs
            </StyledButton>
          </a>          
      </p>
    </StyledCol>
  </StyledJumbotron>
)

export default Hero